.App {
  text-align: center;
  background-color: white;
  transition: 1s;
}
ul {
  text-align: left;
}
li::marker {
  color: rgb(0, 110, 255);
}
.dark {
  color: #212529;
  transition: 1s;
}
.light {
  color: #fff;
  transition: 1s;
}
.AppDark {
  text-align: center;
  background-color: black;
  transition: 1s;
}
.bodyDark {
  background-color: black;
  transition: 1s;
}
#lightDark {
  position: absolute;
  right: 4%;
  top: 4%;
  font-size: 1.5em;
}
#lightDark:hover {
  font-size: 1.7em;
  transition: 0.8s;
}
.blackGit {
  fill: rgb(0, 0, 0);
  transition: 1s;
}
.blackGit {
  fill: #fff;
  transition: 1s;
}
#profilePic {
  height: 300px;
  width: 300px;
  background-color: grey;
  margin-bottom: 1em;
  border-radius: 2em;
}

.divider {
  --divider-color: #000;
  --transition-delay: 1s;
  margin: 1.5em;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--divider-color);
  line-height: 0.1em;
  margin: 1.5em 0 1.5em;
  transition: var(--transition-delay);
}
.dividerDark {
  --divider-color: rgb(255, 255, 255);
  --transition-delay: 1s;
  margin: 1.5em;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--divider-color);
  line-height: 0.1em;
  margin: 1.5em 0 1.5em;
  transition: var(--transition-delay);
}

#about {
  margin-top: 0;
}
.gitlink {
  font-size: 2em;
  position: absolute;
  right: 51%;
  fill: rgb(253, 253, 253);
}

.gitlink:hover {
  font-size: 2em;
  fill: rgb(0, 0, 0);
}
.mobileGit {
  display: none;
  padding: 0px;
  fill: rgb(253, 253, 253);
}
h3 span {
  background: #fff;
  padding: 0 10px;
  margin-top: 1em;
  transition: 1s;
}
h3 span.dark {
  background: rgb(0, 0, 0);
  color: #fff;
  padding: 0 10px;
  margin-top: 1em;
  transition: 1s;
}
.proj {
  margin: 1.5em 0 1.5em;
  /* padding: 1em; */
  border-radius: 1em;
  border: rgb(100, 100, 100) solid 1px;
}
.projDark {
  margin: 1.5em 0 1.5em;
  /* padding: 1em; */
  border-radius: 1em;
  border: rgb(255, 255, 255) solid 1px;
}
.desc > u,
.desc > p,
.desc > u > h3 {
  /* color: rgb(255, 255, 255); */
  color: rgb(0, 0, 0);
  transition: 1s;
}
.descDark > u,
.descDark > p,
.descDark > u > h3 {
  /* color: rgb(255, 255, 255); */
  color: rgb(255, 255, 255);
  transition: 1s;
}
a {
  transition: 1s;
}
.desc > p > a {
  color: blue;
  transition: 1s;
}
.light > a {
  color: aqua;
  transition: 1s;
}
.descDark > p > a {
  color: aqua;
  transition: 1s;
}
.desc > p {
  padding-top: 1em;
}
.descDark > p {
  padding-top: 1em;
}
.desc {
  background-color: rgba(195, 198, 204, 0.849);
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  padding: 1em;
  transition: 1s;
}
.descDark {
  background-color: rgba(83, 83, 83, 0.726);
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  padding: 1em;
  transition: 1s;
}
@media screen and (max-width: 480px) {
  .proj {
    margin: 1.5em 0 1.5em;
    padding: 0px;
    /* border-radius: 0em; */
  }
  .desc {
    border-radius: 0em;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    padding: 0em;
    padding-top: 1em;
  }
}

@media screen and (max-width: 767px) {
  .gitlink {
    right: 16%;
  }
}
@media screen and (max-width: 520px) {
  #profilePic {
    height: 200px;
    width: 200px;
  }
  #aboveAbout {
    margin-bottom: 2em;
  }
  .proj {
    margin-bottom: 4.5em;
    padding-bottom: 5px;
    border: none;
    border-bottom: 1px black double;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .projDark {
    margin-bottom: 4.5em;
    border: none;
    border-bottom: 1px rgb(255, 255, 255) double;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .desc {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .descDark {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .gitlink {
    display: none;
  }
  .gitlinkmob {
    margin-left: 0.5em;
    right: 3%;
    font-size: 1.3em;
    border-radius: 0.1em;
    fill: white;
  }
  p.mobileGit {
    display: block;
    padding: 0px;
  }
}

@media screen and (max-width: 360px) {
  .pics.col-sm-6 {
    padding: 0px;
  }
  img {
    width: 100%;
    height: 210px;
    object-fit: cover;
  }
  #lightDark {
    right: 1.5%;
    top: 1.5%;
  }
}
